import React from "react"
import { graphql } from "gatsby"
import PageRoot from "../components/PageRoot"
import ContainerWithPadding from "../components/ContainerWithPadding"
import EventureGrid from "../components/EventureGrid"
import {
  getCategoryDataSource,
  getImageUrl,
  renderCarouselItem,
} from "../utils"
import ComingSoon from "../components/ComingSoon"
import { DEFAULT_PORTRAIT_IMAGE_SIZE } from "../constants/imageSizes"

const ChannelPage = ({ data }) => {
  const channel = data.eventure.channels_by_pk
  const { name, description, seo, portrait } = channel

  const mergedData = getCategoryDataSource(channel)

  const seoImage = getImageUrl(portrait, DEFAULT_PORTRAIT_IMAGE_SIZE)

  return (
    <PageRoot
      title={seo.title}
      description={seo.description}
      image={process.env.GATSBY_AUTH0_REDIRECT_URI + seoImage}
      showTitle
    >
      <div className="video-channel-page">
        <ContainerWithPadding size={"large"}>
          <EventureGrid
            dataSource={mergedData}
            renderItem={renderCarouselItem}
            emptyItem={<ComingSoon />}
          />
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export const query = graphql`
  query getChannel($channelId: eventure_uuid!) {
    eventure {
      channels_by_pk(id: $channelId) {
        ...PreviewCardChannel
        seo {
          title
          description
          slug
        }
        course_rels: item_rels(
          where: {
            item_type: { _eq: COURSE }
            course: { state: { _eq: PUBLISHED } }
          }
        ) {
          item_type
          item_id
          course {
            ...PreviewCardCourse
          }
        }
        video_rels: item_rels(
          where: {
            item_type: { _eq: VIDEO }
            video: {
              _or: [
                { state: { _eq: PUBLISHED } }
                { state: { _eq: COMING_SOON } }
              ]
            }
          }
        ) {
          item_type
          item_id
          video {
            ...PreviewCardVideo
          }
        }
      }
    }
  }
`

export default ChannelPage
